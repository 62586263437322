import React from 'react';
import classNames from 'classnames';

import { SecondaryButton } from '../../../components';

import css from './ProductTypeFilter.module.css';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

const transformLabel = label => {
  const words = label.split('-');

  if (words.length === 1) {
    return words[0].charAt(0).toUpperCase() + words[0].slice(1);
  }

  const capitalizedWords = words
    .slice(1, 3)
    .map(word => (word === 'new' ? 'Shop' : word.charAt(0).toUpperCase() + word.slice(1)));
  return capitalizedWords.join(' ');
};

const getListingTypeClassName = (label, values) => {
  const selected = values === label;

  switch (label) {
    case 'sell-new-products':
      return classNames(css.listingTypeBtn, css.productsBtn, {
        [css.productsBtnActive]: selected,
      });

    case 'trainings':
      return classNames(css.listingTypeBtn, css.trainingsBtn, {
        [css.trainingsBtnActive]: selected,
      });

    case 'consultations':
      return classNames(css.listingTypeBtn, css.consultationsBtn, {
        [css.consultationsBtnActive]: selected,
      });

    default:
      return css.listingTypeBtn;
  }
};

const ProductTypeFilter = props => {
  const {
    rootClassName,
    className,
    queryParamNames,
    initialValues,
    onSubmit,
    listingTypes,
  } = props;

  const queryParamName = getQueryParamName(queryParamNames);

  // TODO:
  const handleSubmit = listingType => {
    onSubmit({
      [queryParamName]: listingType,
    });
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {listingTypes.map(l => {
        return (
          <SecondaryButton
            key={l}
            onClick={() => handleSubmit(l)}
            inProgress={false}
            className={getListingTypeClassName(l, initialValues[queryParamName])}
          >
            {transformLabel(l)}
          </SecondaryButton>
        );
      })}
    </div>
  );
};

export default ProductTypeFilter;
