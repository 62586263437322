import React from 'react';
import { bool, node, number, string } from 'prop-types';
import classNames from 'classnames';

import FiltersModal from '../FiltersModal/FiltersModal';

import css from './MainPanelHeader.module.css';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    isSortByActive,
    noResultsInfo,
    onManageDisableScrolling,
    availableFilters,
    intl,
    validQueryParams,
    marketplaceCurrency,
    initialValues,
    listingCategories,
    getHandleChangedValueFn,
    isMobile,
    resetAll,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        <FiltersModal
          onManageDisableScrolling={onManageDisableScrolling}
          availableFilters={availableFilters}
          intl={intl}
          validQueryParams={validQueryParams}
          marketplaceCurrency={marketplaceCurrency}
          initialValues={initialValues}
          listingCategories={listingCategories}
          getHandleChangedValueFn={getHandleChangedValueFn}
          isMobile={isMobile}
          resetAll={resetAll}
        />
        {isSortByActive ? <div className={css.sortyByWrapper}>{sortByComponent}</div> : null}
      </div>

      {children}

      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
