import React, { useImperativeHandle } from 'react';
import { bool, func, node, object } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { injectIntl, intlShape } from '../../../util/reactIntl';

import { Form } from '../../../components';

import css from './FilterForm.module.css';

const isCategoriesClear = values => {
  for (const key in values) {
    const match = key.match(/^categoryLevel(\d+)$/);
    if (match) {
      const level = parseInt(match[1], 10); // Extract the numeric level
      if (level >= 2 && Array.isArray(values[key]) && values[key].length > 0) {
        return false;
      }
    }
  }

  return true;
};

const findParent = (array, targetOption, parent = null) => {
  for (const obj of array) {
    if (obj.option === targetOption) {
      return parent;
    }

    if (obj.suboptions && obj.suboptions.length > 0) {
      const result = findParent(obj.suboptions, targetOption, obj);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

const validateSelections = (options, formValues, formApi) => {
  const categories = Object.keys(formValues.values);

  categories.forEach((category, i) => {
    const prevCategory = categories[i - 1];
    const values = formValues.values[category];

    const newValues = values.filter(value => {
      const parentObj = findParent(options, value);

      return prevCategory
        ? !!(
            formValues.values[prevCategory].find(v => v === parentObj?.option) ||
            parentObj?.level === 1
          )
        : true;
    });

    if (newValues.length != values.length) {
      formApi.change(category, newValues);
    }
  });
};

const FilterFormComponent = props => {
  const { liveEdit, onChange, onSubmit, onCancel, onClear, ...rest } = props;

  if (liveEdit && !onChange) {
    throw new Error('FilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'FilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = formState => {
    if (formState.dirty) {
      onChange(formState.values);
    }
  };

  const formCallbacks = liveEdit ? { onSubmit: () => null } : { onSubmit, onCancel, onClear };
  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          id,
          form,
          handleSubmit,
          onClear,
          onCancel,
          style,
          paddingClasses,
          intl,
          children,
          childValuesRef,
          formsRef,
          values,
          categoryName,
          options,
        } = formRenderProps;

        useImperativeHandle(childValuesRef, () => ({
          getValues: () => ({
            ...values,
            categoryLevel1: isCategoriesClear(values) ? [] : [categoryName],
          }),
        }));

        useImperativeHandle(formsRef, () => ({
          getValues: () => ({
            formApi: form,
          }),
        }));

        const handleCancel = () => {
          form.reset();
          onCancel();
        };

        const clear = intl.formatMessage({ id: 'FilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'FilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'FilterForm.submit' });

        const classes = classNames(css.root);

        return (
          <Form
            id={id}
            className={classes}
            onSubmit={handleSubmit}
            tabIndex="0"
            style={{ ...style }}
          >
            <div className={classNames(paddingClasses || css.contentWrapper)}>{children}</div>

            {liveEdit ? (
              <FormSpy
                onChange={formValues => {
                  validateSelections(options, formValues, form);
                  handleChange(formValues);
                }}
                subscription={{ values: true, dirty: true }}
              />
            ) : (
              <div className={css.buttonsWrapper}>
                <button className={css.clearButton} type="button" onClick={onClear}>
                  {clear}
                </button>
                <button className={css.cancelButton} type="button" onClick={handleCancel}>
                  {cancel}
                </button>
                <button className={css.submitButton} type="submit">
                  {submit}
                </button>
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

FilterFormComponent.defaultProps = {
  liveEdit: false,
  style: null,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

FilterFormComponent.propTypes = {
  liveEdit: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  style: object,
  children: node.isRequired,

  // form injectIntl
  intl: intlShape.isRequired,
};

const FilterForm = injectIntl(FilterFormComponent);

export default FilterForm;
