import React, { useState, useRef, createRef } from 'react';

import { Modal, IconFilters, PrimaryButton, IconCloseFilter } from '../../../components';
import FilterComponent from '../FilterComponent';
import { SCHEMA_TYPE_MULTI_ENUM } from '../../../util/types';

import css from '../SearchPage.module.css';

const format = (selectedOptions, queryParamName, schemaType, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = schemaType === SCHEMA_TYPE_MULTI_ENUM && searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

const FiltersModal = props => {
  const [openModel, setOpenModal] = useState(false);
  const childValuesRef = useRef([]);
  const childOptionsRef = useRef();
  const formsRef = useRef([]);
  const toggleFormsRef = useRef([]);

  const {
    onManageDisableScrolling,
    availableFilters,
    intl,
    validQueryParams,
    marketplaceCurrency,
    initialValues,
    listingCategories,
    getHandleChangedValueFn,
    isMobile,
    resetAll,
  } = props;

  if (childValuesRef.current.length !== listingCategories.length) {
    childValuesRef.current = Array(listingCategories.length)
      .fill()
      .map((_, i) => childValuesRef.current[i] || createRef());
  }
  if (formsRef.current.length !== listingCategories.length) {
    formsRef.current = Array(listingCategories.length)
      .fill()
      .map((_, i) => formsRef.current[i] || createRef());
  }
  if (toggleFormsRef.current.length !== listingCategories.length) {
    toggleFormsRef.current = Array(listingCategories.length)
      .fill()
      .map((_, i) => toggleFormsRef.current[i] || createRef());
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    const allForms = formsRef.current.map(ref => ref.current?.getValues());
    allForms.forEach(({ formApi }) => formApi.reset());
    toggleFormsRef.current.forEach(toggle => {
      if (toggle.current.dataset.open === 'true') {
        toggle.current.click();
      }
    });
    resetAll();
  };

  const handleToggleModal = () => {
    window.scrollTo(0, 0);
    setOpenModal(prevState => !prevState);
  };

  const handleSubmit = () => {
    const allValues = childValuesRef.current.map(ref => ref.current?.getValues());
    const options = childOptionsRef.current?.getOptions();
    const { schemaType, searchMode, queryParamNames } = options;
    const onSubmit = getHandleChangedValueFn(true);
    const mergedData = allValues.reduce((acc, curr) => {
      for (const key in curr) {
        if (!acc[key]) acc[key] = [];
        acc[key] = acc[key].concat(curr[key]);
      }
      return acc;
    }, {});

    const usedValue = queryParamNames.reduce((acc, newKey, index) => {
      const oldKey = `categoryLevel${index + 1}`;
      if (mergedData[oldKey]) {
        acc[newKey] = mergedData[oldKey];
      }
      return acc;
    }, {});
    const formattedValues = Object.keys(usedValue).reduce((acc, key) => {
      const values = usedValue[key];
      return {
        ...acc,
        ...format(values, key, schemaType, searchMode),
      };
    }, {});

    onSubmit(formattedValues);
    setOpenModal(false);
  };

  return (
    <div className={css.filterModalWrapper}>
      <PrimaryButton className={css.toggleModalBtn} onClick={handleToggleModal}>
        <IconFilters />
        {intl.formatMessage({ id: 'FiltersModal.btnLabel' })}
      </PrimaryButton>
      <Modal
        containerClassName={css.filtersModalContainerClassName}
        id="FiltersModal"
        isOpen={openModel}
        onClose={handleCloseModal}
        usePortal={!isMobile}
        onManageDisableScrolling={onManageDisableScrolling}
        hideCloseBtn
      >
        <div className={css.closeFilterWrapper}>
          {intl.formatMessage({ id: 'FilterPlain.closeFilterLabel' })}

          <button onClick={handleCloseModal}>
            <IconCloseFilter />
          </button>
        </div>
        <div className={css.filtersWrapper}>
          {availableFilters.map(filterConfig => {
            const key = `SearchFiltersDesktop.${filterConfig.scope || 'built-in'}.${
              filterConfig.key
            }`;
            return (
              <FilterComponent
                key={key}
                idPrefix="SearchFiltersDesktop"
                className={css.filter}
                config={filterConfig}
                listingCategories={listingCategories}
                marketplaceCurrency={marketplaceCurrency}
                urlQueryParams={validQueryParams}
                initialValues={initialValues}
                getHandleChangedValueFn={getHandleChangedValueFn}
                intl={intl}
                showAsPopup={false}
                childValuesRef={childValuesRef}
                childOptionsRef={childOptionsRef}
                toggleFormsRef={toggleFormsRef}
                formsRef={formsRef}
                liveEdit
                isDesktop
              />
            );
          })}
        </div>
        <div className={css.btnsWrapper}>
          <PrimaryButton className={css.btnClear} onClick={handleCloseModal}>
            {intl.formatMessage({ id: 'FilterPlain.clear' })}
          </PrimaryButton>
          <PrimaryButton className={css.btnDone} onClick={handleSubmit}>
            {intl.formatMessage({ id: 'FilterPlain.done' })}
          </PrimaryButton>
        </div>
      </Modal>
    </div>
  );
};

export default FiltersModal;
