import React, { useImperativeHandle, useState } from 'react';
import { array, arrayOf, func, node, number, object, oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM } from '../../../util/types';
import { FieldCheckbox } from '../../../components';
import FilterPlain from '../FilterPlain/FilterPlain';

import css from './SelectMultipleCategoryFilter.module.css';

function filterInitialValues(topCategory, marketplaceOptions, initialValues) {
  const filteredValues = {};
  const topCategoryOptions = marketplaceOptions.find(({ option }) => option === topCategory);

  function findOptionByOption(options, optionValue) {
    for (const option of options) {
      if (option.option === optionValue) {
        return option;
      }
      if (option.suboptions) {
        const found = findOptionByOption(option.suboptions, optionValue);
        if (found) return found;
      }
    }
    return null;
  }

  function areAllFieldsEmptyArrays(obj) {
    return Object.values(obj).every(value => Array.isArray(value) && value.length === 0);
  }

  for (const [label, options] of Object.entries(initialValues)) {
    if (options.includes(topCategory)) continue;

    const filteredOptions = options.filter(opt => {
      return !!findOptionByOption(topCategoryOptions.suboptions, opt);
    });

    filteredValues[label] = filteredOptions;
  }

  if (!areAllFieldsEmptyArrays(filteredValues)) {
    filteredValues.categoryLevel1 = [topCategory];
  }

  return filteredValues;
}

const pickValidOptions = (options, level = 1) => {
  const isString = str => typeof str === 'string';
  const isValidOptions = opts => Array.isArray(opts);
  return options.reduce((picked, optionConfig) => {
    const { option, label, suboptions } = optionConfig;
    const isValid = isString(option) && isString(label);
    const suboptionsMaybe = isValidOptions(suboptions)
      ? { suboptions: pickValidOptions(suboptions, level + 1) }
      : {};
    const validOptionConfigMaybe = isValid ? [{ option, label, level, ...suboptionsMaybe }] : [];
    return [...picked, ...validOptionConfigMaybe];
  }, []);
};

const getSuboptions = optionConfig => optionConfig.suboptions;
const hasSuboptions = optionConfig => getSuboptions(optionConfig)?.length > 0;

const getInitialValues = initialValues => {
  return Object.fromEntries(
    Object.entries(initialValues).map(([key, value]) => {
      const newKey = key.startsWith('pub_') ? key.replace('pub_', '') : key;
      return [newKey, value.split(',')];
    })
  );
};

const GroupOfFieldCheckboxes = props => {
  const { id, className, name, options, level, namedInitialValues, categoryName } = props;

  const [state, setState] = useState(() => {
    const values = namedInitialValues[name + level] ?? [];
    return values.reduce((acc, v) => ({ ...acc, [v]: true }), {});
  });

  const updateState = opt => e => {
    e.persist();
    const checked = e.target.checked;
    setState(prevState => ({ ...prevState, [opt]: checked }));
  };

  return (
    <fieldset className={className}>
      <ul className={css.list}>
        {options.map(optionConfig => {
          const { option, label, suboptions } = optionConfig;
          const fieldId = `${id}.${option}`;
          const checked = state[option];

          return (
            <li key={fieldId} className={css.item} data-test={name + level}>
              <FieldCheckbox
                id={fieldId}
                name={name + level}
                label={label}
                value={option}
                textClassName={classNames(css.textClassName, { [css.boldCategories]: level === 2 })}
                onChange={updateState(option)}
              />

              {hasSuboptions(optionConfig) && checked ? (
                <GroupOfFieldCheckboxes
                  className={css.fieldGroupPlain}
                  name={name}
                  id={`${categoryName}${level + 1}-checkbox-group`}
                  categoryName={categoryName}
                  options={suboptions}
                  level={level + 1}
                  namedInitialValues={namedInitialValues}
                />
              ) : null}
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const createName = (categoryName, postfix) => {
  const words = categoryName.split(' ');
  const camelCaseBase = words
    .map((word, index) => {
      const lower = word.toLowerCase();
      return index === 0 ? lower : lower.charAt(0).toUpperCase() + lower.slice(1);
    })
    .join('');

  const capitalizedPostfix = postfix.charAt(0).toUpperCase() + postfix.slice(1);

  return camelCaseBase + capitalizedPostfix;
};

const SelectMultipleCategoryFilter = props => {
  const {
    rootClassName,
    className,
    id,
    name,
    label,
    options,
    initialValues,
    contentPlacementOffset,
    onSubmit,
    queryParamNames,
    schemaType,
    searchMode,
    showAsPopup,
    childValuesRef,
    childOptionsRef,
    toggleFormsRef,
    formsRef,
    ...rest
  } = props;

  const labelSelectionForPlain = '';
  const namedInitialValues = getInitialValues(initialValues);

  useImperativeHandle(childOptionsRef, () => ({
    getOptions: () => ({
      schemaType,
      searchMode,
      queryParamNames,
    }),
  }));

  const validOptions = pickValidOptions(options);

  return (
    <>
      {validOptions.map((option, i) => {
        const nestedOptions = option.suboptions;
        const labelPlain = option.label || label;
        const categoryName = createName(labelPlain, name);
        const level = 1;
        const initialValuesByOption = filterInitialValues(
          option.option,
          validOptions,
          namedInitialValues
        );

        return (
          <FilterPlain
            key={option.label}
            className={className}
            rootClassName={rootClassName}
            label={labelPlain}
            labelSelection={labelSelectionForPlain}
            // isSelected={hasInitialValues}
            isSelected={false}
            id={`${option.label}.plain`}
            liveEdit
            onSubmit={() => {}}
            initialValues={initialValuesByOption}
            childValuesRef={childValuesRef.current[i]}
            toggleFormsRef={toggleFormsRef.current[i]}
            formsRef={formsRef.current[i]}
            categoryName={option.option}
            options={validOptions}
            {...rest}
          >
            <GroupOfFieldCheckboxes
              className={css.fieldGroupPlain}
              name={name}
              id={`${categoryName}${level + 1}-checkbox-group`}
              categoryName={categoryName}
              options={nestedOptions}
              level={level + 1}
              namedInitialValues={initialValuesByOption}
            />
          </FilterPlain>
        );
      })}
    </>
  );
};

SelectMultipleCategoryFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
  searchMode: null,
};

SelectMultipleCategoryFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  searchMode: oneOf(['has_all', 'has_any']),
  schemaType: oneOf([SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM]).isRequired,
  initialValues: object,
  contentPlacementOffset: number,
};

export default SelectMultipleCategoryFilter;
